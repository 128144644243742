
import { Vue, Component, Prop } from 'vue-property-decorator';
import FormBlock from '@/components/detailBlocks/FormBlock.vue';

@Component({
  name: 'DossierAuditBlock',
  components: {
    FormBlock,
  },
})
export default class DossierAuditBlock extends Vue {
  @Prop({ required: true })
  private readonly blockId!: number;

  @Prop({ required: true })
  protected readonly navigationSlug!: string;

  @Prop({ required: true })
  protected readonly dossierTypeSlug!: string;

  @Prop({ required: true })
  protected readonly dossierId!: number;

  @Prop({ required: true })
  private readonly title!: string;

  @Prop()
  private readonly context?: string;
}

